@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.hibernation-page {
  text-align: center;
  margin: 20px auto; /* Center the content */
  padding: 20px;
  border-radius: 10px;
  background-color: #ffe4e1; /* Light pink background by default */
  border: 2px solid #ff69b4; /* Hot pink border */
  transition: background-color 0.3s, border 0.3s, color 0.3s; /* Original transitions */
  max-width: 600px; /* Width limit for desktop */
  box-sizing: border-box; /* Include padding and border in width calculation */
  position: relative; /* Relative position to contain absolute positioned elements */
  overflow: hidden; /* Prevent overflow */
}

.hibernation-page.resting {
  background-color: #add8e6; /* Light blue background when resting */
  border: none; /* Remove border when resting */
}

.hibernation-page.resting .seal-text {
  color: #ffffff; /* White text color when resting */
}

.seal-status {
  margin-top: 20px;
}

.seal-text {
  font-family: 'Press Start 2P', cursive;
  color: #ff69b4; /* Hot pink color by default */
  font-size: 18px;
  margin-bottom: 10px;
}

.seal-image {
  width: 200px;
  margin-bottom: 20px; /* Add margin to create space between image and button */
}

.rest-button {
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Press Start 2P', cursive;
  color: #ff69b4; /* Hot pink text color */
  background-color: #ffffff; /* White background */
  border: 2px solid #ff69b4; /* Hot pink border */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s; /* Original transitions */
}

.rest-button:hover {
  background-color: #ff69b4; /* Hot pink background on hover */
  border-color: #ff69b4; /* Hot pink border on hover */
  color: #ffffff; /* White text color on hover */
}

.hibernation-page.resting .rest-button {
  color: #ffffff; /* White text color on button when resting */
  background-color: #49aad0; /* Sky blue background when resting */
  border: 2px solid #49aad0; /* Sky blue border when resting */
}

.hibernation-page.resting .rest-button:hover {
  background-color: #0981eb; /* Steel blue on hover when resting */
  border-color: #0981eb; /* Steel blue border on hover when resting */
}

.cat {
  position: absolute;
  width: 50px; /* Larger cat size */
  height: 50px;
  background-image: url('../sprites/cat.png'); /* Use your pixelated cat image */
  background-size: cover;
  pointer-events: none; /* Prevent cats from blocking clicks */
  animation: fade-out 2s forwards; /* Fade out animation */
}

@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0; transform: translateY(-20px); } /* Move up as it fades */
}