@import 'general';

.project-section {
  color: $textColor;
  margin-top: 30px;

  .section-title {
    @include section-title();
  }

  .project-list {
    display: flex;
    flex-wrap: wrap;
    .project-preview {
      width: 300px;
      margin: 20px 20px;
      font-family: $textFont;
      font-size: 14px;

      .thumbnail {
        img {
          width: 300px;
          height: 200px;
          border-radius: 8px;
        }
      }

      .descriptions {
        margin-top: 6px;
        a {
          @include link();
        }

        .skill {
          font-weight: 300;
          font-style: italic;
          margin-bottom: 3px;
        }

        .title {
          font-weight: 700;
          margin-bottom: 6px;
        }

        .description {
        }
      }
    }
  }
}