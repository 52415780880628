@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

$titleFont: 'Lato', sans-serif;
$textFont: 'Open Sans', sans-serif;
$textColor: #333333;
$lineColor: #a3a3a3;
$highlightTextColor: darkorange;
$highlightBlockColor: orange;

// media
$phone: "only screen and (min-width : 480px)";
$tablet: "screen and (min-width : 961px)";
$laptop: "screen and (min-width : 1025px)";
$desktop: "screen and (min-width : 1281px)";


@mixin section-title() {
  margin: 10px 0;
  margin-top: 25px;
  padding: 10px 20px;
  font-family: $titleFont;
  font-size: 24px;
  font-weight: 500;
  border-bottom: 1px solid lightgrey;
  color: $textColor;
}

.section-title {
  @include section-title();
}

@mixin link() {
  color: $textColor;
  text-decoration: none;
  border-bottom: 1px dotted $lineColor;

  &:hover {
    color: $highlightTextColor;
  }
}

@mixin link-button($originalColor: darkgrey, $highlightColor: orange) {
  margin-right: 15px;
  padding: 3px 10px;
  background: $originalColor;
  border-radius: 4px;

  text-decoration: none;
  color: white;

  &:hover {
    background: $highlightColor;
  }
}

@mixin link-button-bright($lineColor: black, $hoverBackground: black, $textColor: black) {
  margin-right: 15px;
  padding: 3px 10px;
  background: none;
  border-radius: 4px;
  border: $lineColor 1px solid;

  text-decoration: none;
  color: $textColor;

  &:hover {
    color: white;
    background: $hoverBackground;
  }
}

@mixin link-woodworking() {
  color: $textColor;
  text-decoration: none;
  border-bottom: 1px dotted $lineColor;

  &:hover {
    color: $textColor;
    border-bottom: 1.5px solid $textColor;
  }
}

.body-part {
  margin-left: 15%;
  margin-top: 60px;
  margin-right: 20%;

  // For phone screens
  @media #{$phone} {
    margin-left: 5%;
    margin-right: 5%;
  }

  // For tablet screens
  @media #{$tablet} {
    margin-left: 10%;
    margin-right: 10%;
  }

  // For laptop screens
  @media #{$laptop} {
    // You can adjust the margins or leave them as they are
  }

  // For desktop screens
  @media #{$desktop} {
    // You can adjust the margins or leave them as they are
  }
}



