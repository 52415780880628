@import 'general';

nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 70%;
  min-height: 30px;

  background: darkgrey;
  display: flex;
  justify-content: space-between;
  font-family: $titleFont;

  padding-left: 15%;
  padding-right: 15%;
  padding-top: 10px;
  padding-bottom: 10px;


  .navigation {
    display: flex;
    align-items:flex-end;
    flex-wrap: wrap;

    .name-brand {
      min-width: 100px;
      display: inline;
      margin-bottom: 5px;
      margin-right: 15px;

      a {
        color: white;
        text-decoration: none;
        display: inline;
        font-weight: 600;
        font-size: 20px;
      }
      
    }

    .menu {
      display: flex;
      //margin: auto;
      margin-right: 0;
      margin-bottom: 0;
      align-items:flex-end;

      button, a {

        margin-right: 15px;
        font-family: $titleFont;
        font-size: 16px;
        color: white;
        text-decoration: none;
        margin-bottom: 7px;

        background: none;
        border: none;

        &:hover, &:focus, &:visited {
          background: none;
          border: none;
          outline:none;
          cursor: pointer;
        }

        &:hover {
          color: lightgrey;
        }

      }

    }
  }


  .info {
    margin-right: 50px;
    display: flex;
    width: 200px;
    height: 30px;
    justify-content: flex-end;

    div {
      display: inline;
      width: 20px;
      margin: 7px 8px;
    }

    .linkedin {
      svg path {
        fill: white !important;
      }
    }

    .googlescholar {
      svg {
        width: 28px;
        rect {
          fill: none !important;
        }

        path {
          fill: white;
        }
      }
    }

    .github {
      width: 20px;
      svg g path {
        fill: white !important;
      }
    }
  }
}

.footer {
  display: flex;
  padding: 20px;
  background: lightgrey;
  margin-top: 40px;

  div, a {
    font-size: 14px;
    font-family: $titleFont;
    color: white;
    margin: auto;

    a {
      text-decoration: none;
      border-bottom: 1px dotted white;
    }

    div {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}