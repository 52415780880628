.about-container {
  display: flex;
  flex-direction: column; // Stack children vertically on mobile
  color: $textColor;
  align-items: center; // Center align for mobile

  img {
    align-self: start;
    margin: 20px;
    display: block; // Instead of inline-block for mobile
    height: auto; // Adjust the height to maintain aspect ratio
    max-width: 50%; // Set a max-width for mobile
  }

  .self-description-container {
    width: 90%; // Take up most of the screen width on mobile
    font-family: $textFont;
    margin: 10px;
    font-size: 14px; // Slightly smaller font size for mobile

    .paragraph {
      margin-top: 2px;
      margin-bottom: 5px;
    }

    .semibold {
      font-weight: 600;
    }

    .my-name {
      font-family: $titleFont;
      font-size: 24px; // Adjust for mobile
      font-weight: 600;
    }

    a {
      @include link();
    }
  }

  // Media query for larger screens (tablet)
  @media #{$tablet} {
    flex-direction: row; // Switch back to horizontal layout

    img {
      margin: 40px 30px 30px 70px;
      height: 300px;
    }

    .self-description-container {
      width: 600px;
      margin: 30px 30px 10px 10px;
      font-size: 14px;
    }
  }

  // You can continue adding media queries for $laptop and $desktop if needed...
}