@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$woodworkingTitleFont: 'Raleway', sans-serif;
$woodworkingTextFont: 'Open Sans', sans-serif;


.woodworking-page {
  display: block;
  overflow: hidden;
  position: relative;
  min-height: 100vh;

  nav {
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    font-family: $woodworkingTitleFont;
    border-bottom: #aaaaaa 0.5px solid;

    .logo {
      font-size: 45px;
      font-weight: 800;

      a {
        font-family: $woodworkingTitleFont;
        text-decoration: none;
        color: #333333;
      }
    }

    .selections {
      padding-top: 25px;
      a {
        margin-left: 60px;

        font-size: 16px;
        font-weight: 400;

        background: none;
        border: none;
        color: #636363;
        text-decoration: none;

        &:hover, &:focus {
          color: black;
          background: none;
          border: none;
          outline:none;
          cursor: pointer;
        }
      }
    }
  }

  .woodworking-portfolio {
    display: flex;
    flex-wrap: wrap;
    color: #333333;

    .preview {
      width: 350px;
      margin:30px 60px 30px 0;
      font-size: 16px;

      .thumbnail {
        img {
          object-fit: cover;
          width: 350px;
          height: 250px;
          border-radius: 6px;
        }
      }

      .description {
        margin-top: 6px;

        .links {
          display: flex;
          flex-wrap: wrap;
          margin-top: 16px;
          .button {
            margin-bottom: 12px;
          }

          a {
            font-size: 14px;
            @include link-button-bright(#333333, #333333, #333333);
          }
        }

        .material {
          font-weight: 300;
          font-style: italic;
          margin-bottom: 3px;
        }

        .title {
          font-weight: 700;
          margin-bottom: 6px;
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;

    color: #636363;
    display: block;
    font-family: $woodworkingTitleFont;
    text-align: center;
    border-top: #aaaaaa 0.5px solid;
    background: white;
  }
}

.woodworking-page-inner {
  font-family: $woodworkingTextFont;
  font-size: 16px;

  margin: auto;
  margin-top: 120px;

  width: 70vw;
  padding-bottom: 40px;
}

.woodworking-project-page {
  font-family: $woodworkingTextFont;
  font-size: 16px;

  max-width: 800px;

  margin: auto;
  margin-top: 120px;
  margin-bottom: 120px;

  padding-bottom: 40px;

  img {
    display: block;
    max-width: 100%;
    max-height: 60vh;
    margin-left: auto;
    margin-right: auto;
  }

  a {
    @include link-woodworking();
  }

  h2 {
    margin-top: 40px;
  }
}



