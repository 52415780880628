@import 'general';
@import './navbar.scss';
@import './about.scss';
@import './research.scss';
@import './awards.scss';
@import './teaching.scss';
@import './projects.scss';
@import './conference.scss';
@import './demo.scss';

@import './woodworking/portfolio.scss';

div.page-container {
  margin: 0;
}

body {
  margin: 0;
}

